<template>
  <a-modal
    center
    :width="400"
    :visible="true"
    :footer="null"
    :mask-closable="false"
    @cancel="onCloseModal"
  >
    <a-row class="mt-4 mb-4" :gutter="16">
      <a-col :span="10">
        {{ $t('finance.order_number') }}
      </a-col>
      <a-col :span="14">
        <a-input-search
          placeholder="Cari No. Order"
          :loading="isLoading || isSearching"
          :disabled="isLoading || isSearching"
          enter-button
          @search="onSearchOrder"
        />
      </a-col>
    </a-row>
    <h5 class="mb-2">
      {{ $t('finance.order_detail') }}
    </h5>
    <a-row class="mb-2" :gutter="16">
      <a-col :span="10">
        {{ $t('finance.order_number') }}
      </a-col>
      <a-col :span="14">
        <a-skeleton
          class="loading-description"
          :loading="isLoading || isSearching"
          :paragraph="{ rows: 0 }"
        >
          {{ detail.order_number }}
        </a-skeleton>
      </a-col>
    </a-row>
    <a-row class="mb-2" :gutter="16">
      <a-col :span="10">
        {{ $t('finance.payment_type') }}
      </a-col>
      <a-col :span="14">
        <a-skeleton
          class="loading-description"
          :loading="isLoading || isSearching"
          :paragraph="{ rows: 0 }"
        >
          {{ detail.payment_type }}
        </a-skeleton>
      </a-col>
    </a-row>
    <a-row class="mb-2" :gutter="16">
      <a-col :span="10">
        {{ $t('finance.total_bill') }}
      </a-col>
      <a-col :span="14">
        <a-skeleton
          class="loading-description"
          :loading="isLoading || isSearching"
          :paragraph="{ rows: 0 }"
        >
          {{ format(detail.total_amount, 'IDR') || '-' }}
        </a-skeleton>
      </a-col>
    </a-row>
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-row class="mb-2" :gutter="16">
        <a-col :span="10">
          {{ $t('finance.total_pay') }}
        </a-col>
        <a-col :span="14">
          <a-form-model-item prop="amount">
            <a-skeleton
              class="loading-description"
              :loading="isLoading || isSearching"
              :paragraph="{ rows: 0 }"
            >
              <InputNumber
                v-model="form.amount"
                :disabled="!detail.payment_id"
                @blur="onResetForm"
              />
            </a-skeleton>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="text-center">
        <a-button
          type="primary"
          size="large"
          class="mr-4"
          ghost
          :disabled="isLoading || isSearching"
          @click="onCloseModal"
        >
          {{ $t('utils.cancel') }}
        </a-button>
        <a-button
          type="primary"
          size="large"
          :disabled="isLoading || isSearching"
          @click="onSubmit"
        >
          {{ $t('finance.create') }}
        </a-button>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getSeller, getDetailOrderByOrderNo } from '@/api/channels/distributor'
import { addTransfer } from '@/api/channels/distributor'
import { useCurrency } from '@/composable/useCurrency'
import { useInputNumber } from '@/composable/useInputNumber'
import InputNumber from '@/components/Input/InputNumber.vue'

export default {
  components: {
    InputNumber,
  },
  setup() {
    const { format } = useCurrency()
    const { toNumber } = useInputNumber()
    return { format, toNumber }
  },
  data() {
    const validateAmount = (rule, value, callback) => {
      const result = +this.detail.total_amount - this.toNumber(value)
      if (value.trim() === '') {
        callback(new Error('Total Bayar tidak boleh kosong'))
      } else if (result !== 0) {
        callback(new Error('Total Bayar harus sama dengan Total Tagihan'))
      } else {
        callback()
      }
    }
    return {
      sellerId: '',
      isLoading: false,
      isSearching: false,
      form: {
        amount: '',
      },
      detail: {
        order_number: '-',
        payment_type: '-',
        total_amount: null,
        payment_id: '',
      },
      rules: {
        amount: [{ required: true, validator: validateAmount, trigger: 'blur' }],
      },
    }
  },
  computed: {
    businessId() {
      return Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
    },
  },
  watch: {
    '$route.query.warehouse_id'() {
      this.fetchSeller()
    },
  },
  mounted() {
    this.fetchSeller()
  },
  methods: {
    async fetchSeller() {
      this.isLoading = true
      const bodyValue = {
        businessId: this.businessId,
        params: {
          warehouse_id: this.$route.query.warehouse_id,
        },
      }
      await getSeller(bodyValue)
        .then(({ data: { data: response } }) => {
          this.sellerId = response.length ? response[0].seller_id : ''
        })
        .catch(err => {
          this.sellerId = ''
          console.error(err)
        })
        .finally(() => this.isLoading = false)
    },
    async onSearchOrder(value) {
      this.isSearching = true
      const bodyValue = {
        id: this.$route.params.id,
        businessId: this.businessId,
        sellerId: this.sellerId,
        orderNo: value,
      }
      await getDetailOrderByOrderNo(bodyValue)
        .then(({ data: response }) => {
          if (response.data === null) {
            this.$notification.error({
              message: 'Gagal',
              description: response.message,
            })
          } else if (response.message === 'Success') {
            const { payment_id, payment_type, total_amount, orders, status } = response.data
            if (status === "CONFIRMED") {
              this.$notification.warning({
                message: 'Warning!',
                description: `Tagihan untuk nomor order ${orders[0].order_number} tersebut sudah terbayar`,
              })
            } else if (status ==='REJECTED' || status === "CANCELED") {
              this.$notification.error({
                message: 'Gagal',
                description: `untuk Order number ${orders[0].order_number} tidak dapat diproses karena sudah berstatus Batal`,
              })
            } else {
              this.detail = {
                order_number: orders[0].order_number,
                payment_type,
                total_amount,
                payment_id,
              }
            }
          } else {
            this.detail = {
              order_number: '-',
              payment_type: '-',
              total_amount: null,
              payment_id: '',
            }
          }
        })
        .catch(err => {
          this.detail = {
            order_number: '-',
            payment_type: '-',
            total_amount: null,
            payment_id: '',
          }
          this.$notification.error({
            message: 'Gagal',
            description: err?.response?.data?.message,
          })
          console.error(err)
        })
        .finally(() => {
          this.form.amount = null
          this.isSearching = false
        })
    },
    onSubmit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          const bodyValue = {
            businessId: this.businessId,
            id: this.$route.params.id,
            paymentId: this.detail.payment_id,
            data: {
              amount: this.toNumber(this.form.amount),
              bank_valid: true,
              image_valid: true,
              currency: 'IDR',
              payment_type: this.detail.payment_type,
            },
          }
          await addTransfer(bodyValue)
            .then(({ data: response }) => {
              if (response.message === 'Success') {
                this.onCloseModal()
                this.$notification.success({
                  message: 'Berhasil',
                  description: 'Pembayaran Berhasil dibuat',
                })
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: response.message,
                })
              }
            })
            .catch(err => {
              this.$notification.error({
                message: 'Gagal',
                description: 'Pembayaran Gagal dibuat',
              })
              console.error(err)
            })
            .finally(() => this.isLoading = false)
        }
      })
    },
    onResetForm() {
      if (this.$refs.ruleForm) this.$refs.ruleForm.clearValidate()
    },
    onCloseModal() {
      this.form = {
        amount: '',
      }
      this.detail = {
        order_number: '-',
        payment_type: '-',
        total_amount: null,
        payment_id: '',
      }
      if (this.$refs.ruleForm) this.$refs.ruleForm.resetFields()
      this.$emit('handleCreatePayment', false)
    },
  },
}
</script>

<style lang="scss">
.loading-description .ant-skeleton-title,
.loading-description .ant-skeleton-paragraph {
  margin: 0 !important;
}
</style>

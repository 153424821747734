<template>
  <div class="pbz-font d-flex flex-column justify-content-center">
    <div class="d-flex justify-content-Start">
      <h3>Riwayat Pembayaran</h3>
    </div>

    <div :class="$style.paymentContainer" class="d-flex flex-column justify-content-center">
      <div :class="$style.paymentContent">
        <PaymentHistory />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentHistory from './partials/PaymentHistory.vue'

export default {
  name: 'Payment',
  components: {
    PaymentHistory,
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
<template>
  <a-modal
    :visible="visible"
    :footer="null"
    :width="800"
    :class="$style.modalOrderPaid"
    @cancel="() => $emit('handleOrderPaid', false)"
  >
    <h2 style="text-align: center">
      {{ row.payment_number }}
    </h2>
    <div class="mt-4">
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="list"
        :pagination="false"
      />
      <!-- {{ pages }}
      <Pagination
        class="text-right"
        :total="paginations.total_items"
        :page-size="paginations.size"
        :default-page="pages"
        :total-length-per-page="list?.length"
        @changePage="changePageTagihan"
      /> -->
    </div>
  </a-modal>
</template>

<script>
import historyPayment from '../composables/historyPayment'
// import Pagination from '@/components/Pagination/index.vue'

export default {
  name: 'ModalOrderPaid',
  // components: {
  // Pagination,
  // },
  props: ['list', 'loading', 'paginations', 'row', 'visible'],
  setup() {
    const { changePageTagihan, pages, listTagihan } = historyPayment()

    return {
      changePageTagihan,
      pages,
      listTagihan,
    }
  },
  data: function () {
    return {
      columns: [
        {
          title: 'No. Order',
          dataIndex: 'order_number',
          key: 'order_number',
        },
        {
          title: 'Tgl. Order',
          dataIndex: 'created_at',
          key: 'created_at',
          ellipsis: true,
          customRender: value => {
            const time =
              value && this.$moment(value).format('DD MMM YYYY HH:mm')
            if (time) return time
            else return '-'
          },
        },
        {
          title: 'No. Invoice',
          dataIndex: 'invoice_number',
          key: 'invoice_number',
        },
        {
          title: 'Tgl. Jatuh Tempo',
          dataIndex: 'expired_at',
          key: 'expired_at',
          ellipsis: true,
          customRender: value => {
            const time =
              value && this.$moment(value).format('DD MMM YYYY HH:mm')
            if (time) return time
            else return '-'
          },
        },
        {
          title: 'Outstanding',
          dataIndex: 'order_outstanding_amount',
          key: 'order_outstanding_amount',
          ellipsis: true,
          customRender: value => {
            const temp = value && this.toCurrency(value)
            if (temp) return 'Rp. ' + temp
            else return '-'
          },
        },
        {
          title: 'Pembayaran',
          dataIndex: 'payment_amount',
          key: 'payment_amount',
          ellipsis: true,
          customRender: value => {
            const temp = value && this.toCurrency(value)
            if (temp) return 'Rp. ' + temp
            else return '-'
          },
        },
        {
          title: 'Sisa Outstanding',
          dataIndex: 'calc_outstanding_amount',
          key: 'calc_outstanding_amount',
          ellipsis: true,
          customRender: value => {
            const temp = value && this.toCurrency(value)
            if (temp !== undefined || temp !== null) return 'Rp. ' + temp
            else return '-'
          },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
        },
      ],
    }
  },
  methods: {
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    // onChangePage(pagination, filters, sorter) {
    //   console.log({ pagination })
    //   const pager = { ...this.pagination };
    //   pager.current = pagination.current;
    //   this.pages = pager;
    // },
  },
}
</script>

<style lang="scss" module>
@import '../style.module.scss';
</style>
